<template>
    <Editor :url="url" :fields="fields" :filters="filters"
            icon="mdi-bookmark-check-outline"
            testo-list="Lista categorie"
            testo-insert="Nuova categoria"
            testo-update="Modifica categoria"
            testo-delete="Vuoi cancellare la categoria?">
        <template #form="{instance}">
            <v-text-field v-model="instance.codice" label="Codice"></v-text-field>
            <v-text-field v-model="instance.nome" label="Nome"></v-text-field>
        </template>
    </Editor>
</template>


<script>
import Editor from "@/components/Editor";

export default {
    data: () => ({
        fields: [
            {text: 'Codice', value: 'codice'},
            {text: 'Nome', value: 'nome'},
        ],
        filters: [
            {field: 'nome', label: 'Nome'},
        ]
    }),
    computed: {
        url() {
            return '/checklist/categoria/';
        }
    },
    components: {
        Editor
    }
}
</script>